.Header {
  padding: 20px 0 0 0;
  position: relative;
  z-index: 2;
  text-align: center;
}

.Header_h1 {
  font-size: 20px;
  margin: 0;
}

.Header_Link {
  color: white;
  text-transform: lowercase;

  &:hover {
    color: white;
  }
}
