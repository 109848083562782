.BackgroundStone {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background: url(../img/backgrounds/background-stone.jpg) no-repeat center center fixed;
  background-size: cover;
}
