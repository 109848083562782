@import '../scss/responsive';

.TrackDetails {
  max-width: 100%;
}

// .TrackDetails_section {}

.TrackDetails_section-header {
  margin-bottom: 12px;
}

.TrackDetails .Divider {
  margin: 32px 0 26px 0;
}

/* ----- */
/* TrackDetailsTitle */
/* ----- */

.TrackDetailsTitle {
  margin-top: 6px;
}

.TrackDetailsTitle-name {
  font-size: 24px;
  margin-bottom: 8px;
}

.TrackDetailsTitle_downloads-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.TrackDetailsTitle_downloads-list-item {
  display: inline-block;
  margin-right: 10px;
}

.TrackDetailsTitle_downloads-list-item:last-child {
  margin-right: 0px;
}

.TrackDetailsTitle_download-link {
  font-size: 14px;
}

/* ----- */
/* TrackDetailsLoudness */
/* ----- */

// .TrackDetailsLoudness {}

.TrackDetailsLoudness_no-data {
  color: #a8a8a8;
  font-size: 14px;
}

.TrackDetailsLoudness_list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.TrackDetailsLoudness_list-item {
  flex: 0 0 50%;
  margin-bottom: 14px;
}

@include respond($rMedium) {

  .TrackDetailsLoudness_list {
    flex-wrap: auto;
  }

  .TrackDetailsLoudness_list-item {
    flex: 1 1 auto;
    margin-bottom: 0px;
  }
}

/* ----- */
/* LoudnessStatistic */
/* ----- */

// .LoudnessStatistic {}

.LoudnessStatistic_name {
  font-size: 14px;
  color: #a8a8a8;
  margin-bottom: 6px;
}

.LoudnessStatistic_value {
  font-size: 16px;
}

/* ----- */
/* TrackDetailsDescription */
/* ----- */

.TrackDetailsDescription {
  color: #a8a8a8;
  font-size: 14px;
}

.TrackDetailsDescription_description {
  line-height: 1.5em;
}

.TrackDetailsDescription_description li + li {
  margin-top: 6px;
}

.TrackDetailsDescription_software-header {
  margin-top: 14px;
}

.TrackDetailsDescription_software-list {
  display: block;
  list-style: none;
  padding: 0;
  margin: 2px 0 0 0;
}

.TrackDetailsDescription_software-list-item {
  display: inline-block;
  margin-right: 10px;
}

.TrackDetailsDescription_software-list-item:last-child {
  margin-right: 0px;
}
