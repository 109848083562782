@import '../scss/responsive';

.PageContainer {
  background-color: rgba(black, 0.9);
}

.PageContainer--tracks {

  .PageContainer_content-children {
    padding: 0px 12px;

    @include respond($rSmall) {
      padding: 16px 40px;
    }
  }
}

.PageContainer--track {

  .PageContainer_content-children {
    padding: 0px 12px 0px 12px;

    @include respond($rSmall) {
      padding: 16px 40px 36px 40px;
    }
  }
}

.PageContainer_top {
  position: relative;
  z-index: 1;
  height: 11px;
  background-image: url(../img/borders/border-horizontal.gif);
}

.PageContainer_top_left-corner {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 11px;
  width: 11px;
  background-image: url(../img/borders/border-corner-top-left.gif);
}

.PageContainer_top_right-corner {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  height: 11px;
  width: 11px;
  background-image: url(../img/borders/border-corner-top-right.gif);
}

.PageContainer_bottom {
  position: relative;
  z-index: 1;
  height: 11px;
  background-image: url(../img/borders/border-horizontal.gif);
}

.PageContainer_bottom_left-corner {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 11px;
  width: 11px;
  background-image: url(../img/borders/border-corner-bottom-left.gif);
}

.PageContainer_bottom_right-corner {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  height: 11px;
  width: 11px;
  background-image: url(../img/borders/border-corner-bottom-right.gif);
}

// Content
.PageContainer_content {
  display: flex;
}

.PageContainer_content-left {
  background-image: url(../img/borders/border-vertical.gif);
  flex: 0 0 11px;
}

.PageContainer_content-children {
  flex: 1 0 calc(100% - 22px);
  padding: 14px 12px;

  @include respond($rSmall) {
    padding: 40px;
  }
}

.PageContainer_content-right {
  background-image: url(../img/borders/border-vertical.gif);
  flex: 0 0 11px;
}
