@import '../scss/responsive';
@import '../scss/colors';

.Track {
  display: flex;
  align-items: center;
  padding: 16px 0;

  @include respond($rSmall) {
    padding: 24px 0;
  }
}

.Track--current {
  background-image: linear-gradient(to right, rgba($brandColor, 0), rgba($brandColor, 0.12), rgba($brandColor, 0));

  .Track_title {
    color: white;
  }

  .Track_play-pause-button {

    img {
      opacity: 1;
    }
  }
}

// .Track_item {}

.Track_item--play-pause-button {
  flex: 0 0 37px;
}

.Track_item--info {
  flex: 1 1 auto;
  padding-left: 8px;

  @include respond($rSmall) {
    padding-left: 18px;
  }
}

.Track_play-pause-button {
  height: 37px;
  width: 37px;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: #333333;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }

  img {
    opacity: 0.5;
  }
}

.Track_header {
  display: flex;
  align-items: center;
  padding-bottom: 4px;
}

// .Track_header-item {}

.Track_header-item + .Track_header-item {
  padding-left: 10px;
}

.Track_header-item--title {
  flex: 1 1 auto;
}

.Track_title {
  font-size: 14px;
  color: #bfbfbf;
}

.Track_header-item--tags {
  display: none;

  @include respond($rSmall) {
    display: block;
  }
}

// .Track_header-item--time {}

.Track_slider {
  position: relative;

  .Slider {
    padding: 6px 0;
    background-color: transparent;

    .Slider_track {
      background-color: #4d4d4d;
    }
  }
}

.Track_slider-mask {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
