/* .Playlist {} */

$borderColor: rgb(75, 75, 75);

.Playlist_list {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* .Playlist_list-item {} */

/*
.Playlist_list-item + .Playlist_list-item {
  margin-top: 5px;
}
*/
