@import '../scss/responsive';

.TrackTags {
  display: none;
}

@include respond($rSmall) {

  .TrackTags {
    display: inline;
  }

  .TrackTags_list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  .TrackTags_list-item {
    position: relative;
    font-size: 14px;
    background-color: #262626;
    padding: 1px 4px 1px 0;
    color: #a8a8a8;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    line-height: 1;

    &:before {
      content: " ";
      position: absolute;
      z-index: 1;
      top: 0;
      left: -12px;
      height: 16px;
      width: 13px;
      background-image: url(../img/tags/tag-blue.svg);
      background-position: center left;
      background-repeat: no-repeat;
    }
  }

  .TrackTags_list-item--Ambient {

    &:before {
      background-image: url(../img/tags/tag-green.svg);
    }
  }

  .TrackTags_list-item--Dark {

    &:before {
      background-image: url(../img/tags/tag-black.svg);
    }
  }

  .TrackTags_list-item--Heroic {

    &:before {
      background-image: url(../img/tags/tag-blue.svg);
    }
  }

  .TrackTags_list-item--Orchestral {

    &:before {
      background-image: url(../img/tags/tag-purple.svg);
    }
  }

  .TrackTags_list-item--Piano {

    &:before {
      background-image: url(../img/tags/tag-red.svg);
    }
  }

  .TrackTags_list-item + .TrackTags_list-item {
    margin-left: 20px;
  }

  .TrackTags_tag-text {
    position: relative;
    z-index: 2;
  }
}
