@import '../scss/responsive';

.Player {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: black;
  background-image: url(../img/backgrounds/background-stone-player.jpg);
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: rgb(65, 65, 65);
  padding: 10px 0;
  box-shadow: 0px 0px 7px rgba(black, 1);
}

.Player_items-container {
  display: flex;
  align-items: center;
}

.Player_item + .Player_item {
  padding-left: 5px;
}

.Player_item--buttons-container {
  flex-grow: 0;
  flex-shrink: 1;
}

.Player_item--time-slider-container {
  flex-grow: 1;
  flex-shrink: 0;

  .Slider {
    background-color: transparent;
    padding-left: 0;
    padding-right: 6px;

    .Slider_track {
      background-color: black;
    }

    .Slider_track_fill {
      background-image: linear-gradient(to right, #4f422f, #beb79f);

      &:after {
        border-radius: 0;
        background-image: url(../img/controls/head.svg);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: transparent;
        height: 17px;
        width: 17px;
        top: -7px;
        right: -9px;
      }
    }
  }
}

.Player_item--time-container {
  flex-grow: 0;
  flex-shrink: 1;
}

.Player_item--volume-slider-container {
  flex-grow: 0;
  flex-shrink: 1;
  position: relative;
  padding: 2px 0;

  .Slider {
    display: none;
    position: absolute;
    bottom: 20px;
    left: -2px;
    z-index: 200;
  }

  &:hover {

    .Slider {
      display: block;
    }
  }
}

.Player_volume-icon {
  cursor: pointer;

  &:before {
    content: " ";
    display: inline-block;
    width: 14px;
    height: 12px;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-image: url(../img/controls/volume-spritesheet-white.svg);
  }

  &.Player_volume-icon--mute {

    &:before {
      background-position: 0 0;
    }
  }

  &.Player_volume-icon--low {

    &:before {
      background-position: 0 -12px;
    }
  }

  &.Player_volume-icon--medium {

    &:before {
      background-position: 0 -24px;
    }
  }

  &.Player_volume-icon--high {

    &:before {
      background-position: 0 -36px;
    }
  }
}

.Player_button {
  cursor: pointer;
  display: inline-block;
  border-style: none;
  border-radius: 3px;
  padding: 7px;
  background-color: transparent;
  line-height: 0;

  &:focus {
    outline-style: none;
  }
}

.Player_button + .Player_button {
  margin-left: 5px;
}

.Player_audio {
  display: none;
}

@include respond($rMedium) {

  .Player_item + .Player_item {
    padding-left: 20px;
  }

  .Player_item--volume-slider-container {

    .Slider {
      left: 12px;
    }
  }
}
