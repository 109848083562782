@import '../scss/colors';

.Nav {
  padding: 20px 0;
  position: relative;
  z-index: 2;
}

/* .Nav_nav {} */

.Nav_list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

/* .Nav_list-item {} */

.Nav_list-item + .Nav_list-item {
  margin-left: 20px;
}

.Nav_NavLink {
  transition-property: color;
  transition-duration: 0.15s;
  position: relative;
  padding: 0 10px 10px 10px;
  display: inline-block;
  text-decoration: none;
  color: rgba(white, 0.5);

  &:after {
    content: " ";
    transition-property: background-color, width, left;
    transition-duration: 0.15s;
    display: block;
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 0%;
    height: 2px;
    background-color: rgba(white, 0);
  }

  &:hover {
    color: rgba($brandColor, 0.75);

    &:after {
      left: 25%;
      width: 50%;
      background-color: rgba($brandColor, 0.75);
    }
  }
}

.Nav_NavLink--selected {
  color: $brandColor;

  &:after {
    left: 0;
    width: 100%;
    background-color: $brandColor;
  }

  &:hover {
    color: $brandColor;

    &:after {
      left: 0%;
      width: 100%;
      background-color: $brandColor;
    }
  }
}
