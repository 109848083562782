@import '../scss/colors';

.Slider {
  padding: 12px;
  border-radius: 3px;
  cursor: pointer;
  background-color: rgb(45, 45, 45);
}

.Slider_track {
  border-radius: 1px;
  background-color: white;
}

.Slider_track_fill {
  position: relative;
  border-radius:  1px;
  background-color: white;

  &:after {
    content: " ";
    position: absolute;
    z-index: 1;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: white;
    display: none;
  }
}

.Slider--horizontal {

  .Slider_track {
    height: 3px;
  }

  .Slider_track_fill {
    height: 3px;

    &:after {
      top: -2px;
      right: -4px;
    }
  }
}

.Slider--vertical {

  .Slider_track {
    position: relative;
    height: 200px;
    width: 3px;
  }

  .Slider_track_fill {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 3px;

    &:after {
      top: -4px;
      right: -2px;
    }
  }
}

.Slider--not-zero {

  .Slider_track_fill {
    background-color: $brandColor;

    &:after {
      background-color: $brandColor;
      display: block;
    }
  }
}
