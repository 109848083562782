@import '../../scss/responsive';

/* .AboutPage {} */

/* .AboutPage_content-container {} */

/* .AboutPage_content {} */

.AboutPage_content + .AboutPage_content {
  margin-top: 16px;
}

/* .AboutPage_software-category-container {} */

.AboutPage_software-category-container + .AboutPage_software-category-container {
  margin-top: 16px;
}

/* .AboutPage_software-list {} */

/* .AboutPage_software-list-item {} */

.AboutPage_software-list-item + .AboutPage_software-list-item {
  margin-top: 6px;
}

/* .AboutPage_feeds-list {} */

/* .AboutPage_feeds-list-item {} */

.AboutPage_feeds-list-item + .AboutPage_feeds-list-item {
  margin-top: 6px;
}

@include respond($rMedium) {
  .AboutPage_content + .AboutPage_content {
    margin-top: 0;
  }

  .AboutPage_content-container {
    display: flex;
    justify-content: space-between;
  }

  .AboutPage_content {
    flex: 0 0 48%;
  }
}
