@import './scss/colors';

* {
  box-sizing: border-box;
}

html {
  background-color: black;
}

body {
  margin: 0;
  padding: 0;
  color: white;
  background-color: black;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  /*
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  */
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  /* text-transform: lowercase; */
  /* font-family: 'Girassol', cursive; */
  font-family: 'Playfair Display', serif;
}

a {
  color: $brandColor;
  text-decoration: none;

  &:hover {
    color: $linkHoverColor;
  }
}

a, p, ol, ul, h1, h2, h3, h4, h5, h6 {

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

/*
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*/
