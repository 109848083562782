@import '../scss/colors';

.Time {
  color: rgb(175, 175, 175);
  font-family: monospace;
}

.Time_current-time {
  color: rgb(125, 125, 125);
}

.Time_current-time--not-zero {
  color: $brandColor;
}

.Time_duration {}
