.Footer {
  position: relative;
  z-index: 2;
  padding-bottom: 48px; // This is the same height as the Player component.
};

.Footer_feeds {
  display: flex;
  justify-content: center;
  padding: 12px 0 20px 0;
  font-size: 14px;
}

.Footer_feeds-title {
  margin-right: 6px;
  color: rgb(125, 125, 125);
}

.Footer_feeds-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.Footer_feeds-list-item {}

.Footer_feeds-list-item + .Footer_feeds-list-item {
  margin-left: 6px;
}

.Footer_feeds-list-link {}
